/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/open-sans-v18-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/open-sans-v18-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/open-sans-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/open-sans-v18-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/open-sans-v18-latin-regular.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/open-sans-v18-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/open-sans-v18-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/open-sans-v18-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/open-sans-v18-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/open-sans-v18-latin-italic.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/open-sans-v18-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/open-sans-v18-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/open-sans-v18-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/open-sans-v18-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/open-sans-v18-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/open-sans-v18-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/open-sans-v18-latin-700italic.woff") format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/open-sans-v18-latin-700italic.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}
