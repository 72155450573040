@import "fonts";

$primary: #263238;
$primary-light: #4f5b62;
$primary-dark: #000a12;

$secondary: #fcd734;
$secondary-light: #ffff6b;
$secondary-dark: #c5a600;

$highlight: #aa232a;

$text-dark: $primary-dark;
$text-light: white;

$bp-sm: 560px;
$bp-md: 900px;
$bp-lg: 1200px;

$transition-time: 0.3s;

body {
  margin: 0;
  font-family: "Open Sans", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-light;
  background: $primary-dark;
}

* {
  box-sizing: border-box;
  line-height: 1.5;
}

a {
  color: $secondary;
}
