@import "index";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top-nav {
  .logo-container {
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;

    > a {
      line-height: 0;
    }

    #logo {
      height: 40px;
    }
  }

  .nav-container {
    background-color: #1f292e;
    height: 30px;

    > nav {
      display: flex;
      align-items: center;
      height: 100%;

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1;
        color: $text-light;
        text-decoration: none;
        transition: background-color $transition-time;

        &:hover {
          background-color: $primary-light;
        }

        &.active {
          background-color: $highlight;
        }
      }
    }
  }
}

.page {
  background: #2e3d45;
  flex: 1 0;
}

.footer {
  background: $primary;
}

.restrict-width {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.carousel {
  background: $primary-dark;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  > img {
    height: 100vw;
    max-height: 720px;
  }
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px !important;
  height: 30px;
  background-color: #1f292e;

  > svg {
    margin-right: 8px;
    margin-left: 8px;
  }
}

.dot {
  color: darken(white, 50%) !important;

  &:hover {
    color: white !important;
  }
}

.active-dot,
.active-dot:hover {
  color: white !important;
}

.section-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  .title-spacer {
    flex: 1;
    height: 1px;
    background: white;
  }

  .title {
    margin: 20px;
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: $bp-md) {
    flex-direction: row;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 20px;
  background-color: $primary;
  border-left: 5px solid $highlight;
  transition: box-shadow $transition-time;

  &:hover {
    box-shadow: 0px 0px 15px $primary-dark;
  }

  > img {
    object-fit: contain;
  }

  .card-text-container {
    padding: 20px;

    > h2 {
      margin-top: 0px;
    }
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 20px;
  padding: 20px;
  background-color: $primary;

  border-left: 5px solid $highlight;
  transition: box-shadow $transition-time;

  &:hover {
    box-shadow: 0px 0px 15px $primary-dark;
  }

  @media only screen and (min-width: $bp-md) {
    max-width: 840px;
    flex-direction: row;
  }
}

.text-box {
  flex: 1;

  > p {
    margin-top: 0px;
  }

  @media only screen and (min-width: $bp-md) {
    padding-right: 40px;

    > p {
      margin-bottom: 0px;
    }
  }
}

.donate-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-top: 0px;
  }

  #donate-button {
    width: 200px;
  }

  @media only screen and (min-width: $bp-md) {
    padding-left: 40px;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-media-icon {
  width: 60px;
  padding: 10px;
  fill: $secondary;
  transition: fill $transition-time;

  &:hover {
    fill: $secondary-dark;
  }
}

.order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 20px 20px;

  @media only screen and (min-width: $bp-sm) {
    flex-direction: row;
  }
}

.combo-box {
  flex: 1;
  width: 100%;
  padding-right: 20px !important;
}

.text-field {
  flex: 2;
  width: 100%;
  padding-right: 20px !important;
  padding-bottom: 20px !important;

  @media only screen and (min-width: $bp-sm) {
    padding-bottom: 0 !important;
  }
}

.MuiFormLabel-root,
.MuiFormHelperText-root {
  color: $text-light !important;
}

.MuiInput-underline:before {
  border-color: $text-light !important;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 20px 20px;

  > img {
    width: 100%;
    max-width: 464px;
    margin: 20px;
    border-left: 5px solid $highlight;
    transition: box-shadow $transition-time;

    &:hover {
      box-shadow: 0px 0px 15px $primary-dark;
    }
  }
}
